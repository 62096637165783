import { cn } from '@/lib/utils';
import Image from 'next/image';
import React from 'react';

type Props = {
  title: string;
  imgUrl?: string;
  action?: React.ReactNode;
  filled?: boolean;
  description?: string;
};
export default function EmptyContent({ title, imgUrl, action, filled, description }: Props) {
  return (
    <div
      className={cn(
        'flex-grow flex-1 flex-col items-center justify-center flex px-3 h-full',
        filled && 'rounded-lg bg-gray-brand/40 border-gray-brand/80'
      )}
    >
      <Image
        height={160}
        width={160}
        alt="empty content"
        src={imgUrl || '/assets/icons/empty/ic_content.svg'}
        crossOrigin="anonymous"
      />

      {title && <h6 className="text-center text-gray-brand2 text-sm">{title}</h6>}

      {description && <p className="text-center text-gray-brand3 text-xs mb-4">{description}</p>}

      {action && action}
    </div>
  );
}
