export const SHOP_ENDPOINTS = {
  getAll: '/Event/GetAll',
  getExplore: '/Event/GetAllRevamp',
  getGrouped: '/Event/GetGroupedEvents',
  getProductCategories: '/Shop/shop-categories',
  createEvent: '/Event/CreateEventStore',
  updateEvent: '/Event/Update',
  getEventById: '/Event/GetById',
  getEventBySlug: '/Event/GetBySlug',
  registerForFreeEvent: '/Event/RegisterFreeEvent',
  upcomingEvents: '/Event/GetUpcoming',
  cancelEvent: '/Event/XXXXX',
  openCloseTicketSales: '/Event/yyyyyyy',
  sendEventRemainder: '/Event/yyyyyyy',
  downloadAttendeeList: '/Event/yyyyyyy',
  getAttendeeList: '/Event/GetAttendees',
};
