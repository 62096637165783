export const COMMUNITIES_ENDPOINTS = {
  getUserJoined: '/Community/GetUserJoinedCommunities',
  getAll: '/Community/GetAll',
  getSidePanelData: '/Community/CommunitySidePanelDetails',
  getCommunityById: '/Community/GetById',
  getCommunityBySlug: '/Community/GetBySlug',
  getCommunityPostsById: '/post/getPosts',
  joinOrLeave: '/Community/JoinOrLeave',
  approveOrReject: '/Community/ApproveOrRejectJoinPrivateCommunityRequest',
  getUserJoinedCommunities: '/Community/GetUserJoinedCommunities',
  members: '/Community/GetMembers',
  categories: '/Community/Categories',
  interests: '/Community/Interests',
  userInterests: '/Community/GetAllByUserInterests',
  create: '/Community/Create',
  update: '/Community/Update',

  getAllPosts: '/Post/GetPosts',
  postsByCommunity: '/Post/GetByCommunity',
  getPopularPosts: '/Post/PopularPosts',
  getUserFeeds: '/Post/GetForUserFeedM',
  getCreatorPosts: '/Post/GetByUser',
  getExplore: '/Community/GetAllExplore',

  getAllPeople: '/People/GetAllPeople',

  getFollowing: '/Connections/get-following',
  getFollowers: '/Connections/get-followers',
  getCommunityOwners: '/Community/community-owners',
  getPopularCreators: '/Post/post-creators',
  getOthers: '/Community/others',
  connectToUser: '/Connections/connect-to-user',
  unfollowUser: '/Connections/unfollow-user',
  checkIsFollowing: '/Connections/following',
  toggle: '/Community/toggle',
  delete: '/Community/Delete',
  transferOwnership: '/Community/TransferOwnership',
  getSettings: '/Community/GetCommunitySettings',
  updateSettings: '/Community/UpdateCommunitySettings',
  removeMember: '/Community/RemoveMembers',
  removeAllMembers: '/Community/RemoveAllMembers',
  updateMembersRole: '/Community/ChangeMemberRole',
};
